import { REQUIRED_AUTH_PAGES_WITH_CHILDREN } from '~/constants/general';
import type { RequiredAuthPagesWithChildren } from '~/types/general/globals';
import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware((to) => {
  if (process.server) {
    return;
  }
  const { getUserData } = useAuthStore();
  const parentPathIndex = Number(getUserData.locale !== 'ru') + 1;
  const parentPath = to.path.split('/')[parentPathIndex] as keyof RequiredAuthPagesWithChildren;
  const pageType = (to.params?.pageType || to.params?.mode) as string;
  const exception = (parentPath === 'game' && REQUIRED_AUTH_PAGES_WITH_CHILDREN[parentPath].fun === pageType);

  if (
    REQUIRED_AUTH_PAGES_WITH_CHILDREN[parentPath] &&
    Object.keys(REQUIRED_AUTH_PAGES_WITH_CHILDREN[parentPath]).length &&
    !Object.values(REQUIRED_AUTH_PAGES_WITH_CHILDREN[parentPath]).includes(pageType)
  ) {
    return navigateTo({ path: useLocalePrefixForHref('/') });
  }

  if (!exception && !getUserData.loggedIn) {
    useSessionStorage.set('oldPath', encodeURIComponent(to.fullPath));

    return navigateTo({ path: useLocalePrefixForHref('/login') });
  }
});
